<template>
  <section v-view class="referenzen part">
    <div v-if="!isDesktop" class="constrain">
      <div class="row">
        <div class="col-12 md:col-5 md:offset-1 mobile-references">
          <div class="animate reveal mobile">
            <slider
              ref="sliderRef"
              v-slot="{ item: bild }"
              :slides-per-view="{ desktop: 1, mobile: 1 }"
              :slides-per-group="{ desktop: 1, mobile: 1 }"
              :items="images"
              effect="fade"
              @progress="onProgress"
              @afterInit="updatePage"
              @slideChange="updatePage"
              @resize="updatePage"
              @update="updatePage"
            >
              <box class="image-box" force-aspect-ratio no-padding>
                <Image fade-swap :src="bild" />
              </box>
            </slider>
          </div>
          <page-progress
            v-if="!isDesktop"
            class="progress"
            :start="start"
            :end="end"
            :current-page="page"
            :pages="pages"
            @prev="prev"
            @next="next"
          />
          <Go
            v-for="(reference, index) in payload.references"
            :key="reference.id"
            :class="{ active: index === selectedIndex }"
            :to="reference.link"
            class="mobile-reference"
            @click="showPictureFor(reference)"
          >
            <h3 v-html="reference.titel" />
          </Go>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="animate reveal full-reveal-image">
        <Image fade-swap class="image" :src="bild" />
      </div>
      <div class="constrain">
        <div class="row-12">
          <div class="col-12 md:col-6 md:offset-6 references-container">
            <div
              class="references"
              :style="cssVars({ indexOffset: selectedIndex, amount: payload.references.length })"
            >
              <Go
                v-for="(reference, index) in payload.references"
                :key="reference.id"
                :to="reference.link"
                class="h3 reference"
                :class="{ active: index === selectedIndex }"
                @mouseenter="showPictureFor(reference)"
                @mouseleave="resetPicture()"
              >
                {{ reference.titel }}
              </Go>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import {
  computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { cssVars } from '@/utils/css';
import { useWithinBreakpoint } from '@/composables/breakpoints';
import { usePage, useVisibleProgress } from '@/composables/swiper';
import Go from '../utils/Go.vue';

/*
function useDebounce(fn, delay = 20) {
  const timeout = ref(null);
  return (...args) => {
    if (timeout.value) {
      clearTimeout(timeout.value);
    }
    timeout.value = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}
*/

function useWheel(cb) {
  onMounted(() => {
    window.addEventListener('mousewheel', cb);
  });
  onUnmounted(() => {
    window.removeEventListener('mousewheel', cb);
  });
}

function useScrollCapture(max, cb) {
  const coll = ref(0);
  useWheel((e) => {
    const value = coll.value + e.deltaY;
    if (value < 0) coll.value = 0;
    else if (value >= max) coll.value = max - 1;
    else coll.value = value;
    cb(coll.value);
  });
}

export default {
  components: { Go },
  props: { payload: { type: Object, default: () => ({}) } },
  setup(props) {
    const sliderRef = ref(null);
    const forcedPicture = ref(null);
    const selectedIndex = ref(0);

    const { onProgress, start, end } = useVisibleProgress();
    const { updatePage, page, pages } = usePage();

    const isDesktop = useWithinBreakpoint('md');
    const bild = computed(() => (forcedPicture.value
      ? forcedPicture.value
      : props.payload.references?.[selectedIndex.value]?.vorschaubild));
    const showPictureFor = (reference) => {
      if (reference.vorschaubild && reference.vorschaubild.id) {
        forcedPicture.value = reference.vorschaubild;
      }
    };
    const resetPicture = () => {
      forcedPicture.value = null;
    };

    const lock = () => {
      window.scrollTo(0, 0);
      document.documentElement.style.overflowY = 'hidden';
    };
    const unlock = () => {
      document.documentElement.style.overflowY = 'auto';
    };

    watch(isDesktop, (newIsDesktop) => {
      if (newIsDesktop) {
        lock();
      } else {
        unlock();
      }
    });
    onMounted(lock);
    onUnmounted(unlock);

    watch(page, (newPage) => {
      selectedIndex.value = newPage - 1;
    });

    useScrollCapture(props.payload.references.length * 100, (delta) => {
      const steps = Math.floor(delta / 100);
      selectedIndex.value = steps;
      showPictureFor(props.payload.references[selectedIndex.value]);
    });

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value.next();

    return {
      bild,
      showPictureFor,
      resetPicture,
      selectedIndex,
      cssVars,
      isDesktop,
      onProgress,
      start,
      end,
      updatePage,
      page,
      pages,
      prev,
      next,
      sliderRef,
      images: computed(() => props.payload.references.map((reference) => reference.vorschaubild)),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_grid.scss";

.referenzen {
  position: relative;

  @screen md {
    height: calc(100vh - var(--header-height));
  }

  &:last-of-type {
    margin-bottom: px(75);
  }
}

.references-container {
  margin-top: calc((var(--top-margin) - var(--header-height)) * -1);
  height: calc(100vh - var(--header-height));

  mask-image: linear-gradient(
    to bottom,
    transparent calc(50% - 30vh),
    rgba(0, 0, 0, 1) 50%,
    transparent calc(50% + 30vh)
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent calc(50% - 30vh),
    rgba(0, 0, 0, 1) 50%,
    transparent calc(50% + 30vh)
  );
}

.mobile-reference {
  width: fit-content;
  display: block;

  h3 {
    padding-bottom: px(7);
    margin-bottom: px(21);
  }

  &.active h3 {
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
  }
}

.references {
  display: flex;
  flex-flow: column;
  position: relative;

  top: 50%;
  transform: translateY(var(--offset)) translateY(calc(-1 * var(--element-height)));

  --element-height: calc(var(--element-line-height) + var(--element-margin) * 2);
  --offset: calc(var(--element-height) * var(--index-offset) * -1);

  @include responsive("--element-margin", px(10), px(17));
  @include responsive("--element-line-height", px(22), px(42));

  transition: mask-image var(--speed-fast) ease, -webkit-mask-image var(--speed-fast) ease,
    transform var(--speed-fast) ease;

  .reference {
    cursor: pointer;
    margin: var(--element-margin) 0;
    transition: color var(--speed-fast) ease;

    &:hover,
    &.active {
      color: var(--color-primary);
    }
  }
}

.mobile.reveal {
  @apply rounded-big;
  overflow: hidden;
  width: 100%;
  height: max-content;
}

.image-box {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.full-reveal-image {
  position: fixed;
  z-index: -1;
  overflow: hidden;
  left: 0;
  width: calc(50vw + var(--col-width) - 0.5rem);
  z-index: -1;
  height: 100%;
  @include responsive("margin-top", px(-31), px(-106));

  :deep(img) {
    object-fit: cover;
  }
}

.progress {
  margin: px(10) 0 px(45) 0;
}
</style>
